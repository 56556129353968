/* SkeletonInput.css */

.input-wrapper {
    position: relative;
    width: 100%;
  }
  
  .input-field {
    width: 100%;
    height: 40px;
    padding: 8px;
    font-size: 16px;
  }
  
  .skeleton-input {
    width: 100%;
    height: 40px;
    border-radius: 4px;
    background: linear-gradient(
      -90deg,
      #e0e0e0 0%,
      #f0f0f0 50%,
      #e0e0e0 100%
    );
    background-size: 200% 100%;
    animation: loading 1.5s infinite;
  }
  
  @keyframes loading {
    0% {
      background-position: 200% 0;
    }
    100% {
      background-position: -200% 0;
    }
  }
  
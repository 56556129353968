/* Responsive */

@media (max-width:479px) {
    /* dashboard */

    .llt_dash_dispatch {
        flex-direction: column;
    }

    .llt_dash_heading {
        flex-direction: column;
    }

    .llt_dash_payment_bx_1 h6,
    .llt_dash_vehicle_d1 h6,
    .llt_dash_eway_bx_1 h6 {
        font-size: 18px;
    }

    .llt_dash_branch_date {
        flex-wrap: wrap;
    }

    .llt_dash_branch_date select,
    .llt_dash_branch_date div {
        width: 100%;
    }

    /* dashboard */


    ._fleX_inds ._divForth,
    ._divForth,
    ._tierDivs {
        flex: 0 0 100%;
    }

    ._cityInput_bx {
        flex: 0 0 100%;
    }

    .plan_search_header,
    .plan_search_header ._anvPlan_srOuter_bx {
        flex: 0 0 100% !important;
    }

    ._selectLr_table {
        height: calc(100vh - 550px) !important;
    }

    .__conect_mainBg {
        justify-content: center;
    }

    .__inr_conectBx {
        flex: 0 0 100%;
    }
}

@media (min-width: 480px) and (max-width: 767px) {

    ._fleX_inds ._divForth,
    ._divForth,
    ._tierDivs {
        flex: 0 0 100%;
    }

    .__conect_mainBg {
        justify-content: center;
    }

    .__inr_conectBx {
        flex: 0 0 100%;
    }
}


@media (min-width: 768px) and (max-width: 991px) {

    ._fleX_inds ._divForth,
    ._divForth {
        flex: 0 0 calc(50% - 11px);
    }

    ._tierDivs {
        flex: 0 0 calc(50% - 8px);
    }

    ._rtrBxs {
        flex: 0 0 100%;
        max-width: -webkit-fill-available;
    }

    ._mrkList_otrBx {
        grid-row-gap: 15px;
    }

    ._fleerBxs,
    ._materialBxs,
    ._whtBxs,
    ._peDate,
    ._payTerms_bxs {
        flex: 0 0 calc(33.33% - 8px);
        max-width: none;
        min-width: auto;
    }

    .__conect_mainBg {
        max-width: 810px;
    }

    .__inr_conectBx {
        flex: 0 0 calc(50% - 6px);
    }

    ._mainRl_table.__nWd_table table thead tr:after,
    ._mainRl_table.__nWd_table table tbody tr:after {
        width: 1%;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {

    ._fleX_inds ._divForth,
    ._divForth {
        flex: 0 0 calc(33.33% - 14px);
    }

    ._rtrBxs {
        flex: 0 0 100%;
        max-width: -webkit-fill-available;
    }

    ._mrkList_otrBx {
        grid-row-gap: 15px;
    }

    ._fleerBxs,
    ._materialBxs,
    ._whtBxs,
    ._peDate,
    ._payTerms_bxs {
        flex: 0 0 calc(33.33% - 8px);
        max-width: none;
        min-width: auto;
    }

    .__conect_mainBg {
        max-width: 890px;
    }

    .__inr_conectBx {
        flex: 0 0 calc(50% - 6px);
    }

    ._mainRl_table.__nWd_table table thead tr:after,
    ._mainRl_table.__nWd_table table tbody tr:after {
        width: 2%;
    }
}

@media (min-width: 1200px) and (max-width: 1365px) {

    ._fleX_inds ._divForth,
    ._divForth {
        flex: 0 0 calc(33.33% - 14px);
    }


    ._mrkList_otrBx {
        grid-row-gap: 15px;
        justify-content: flex-start;
    }

    ._fleerBxs,
    ._materialBxs,
    ._whtBxs,
    ._peDate,
    ._payTerms_bxs,
    ._rtrBxs {
        flex: 0 0 calc(33.33% - 10px);
        max-width: none;
        min-width: auto;
    }

    .__conect_mainBg {
        max-width: 1130px;
    }

    .__inr_conectBx {
        flex: 0 0 calc(33.33% - 8px);
    }

    .one_3rdDiv {
        flex: 0 0 35%;
    }

    .twiceDiv {
        flex: 0 0 62%;
    }

    ._mainRl_table.__nWd_table table thead tr:after,
    ._mainRl_table.__nWd_table table tbody tr:after {
        width: 5%;
    }


}

@media (min-width: 1366px) and (max-width: 1600px) {
    ._mrkList_otrBx {
        justify-content: flex-start;
    }

    .__conect_mainBg {
        max-width: 1200px;
    }

    .__inr_conectBx {
        flex: 0 0 calc(33.33% - 8px);
    }
}

@media (min-width: 1601px) and (max-width: 1920px) {

    .__conect_mainBg {
        max-width: 1500px;
    }

    .__inr_conectBx {
        flex: 0 0 calc(25% - 9px);
    }
}


/* Responsive */